/****************** buttons ******************/
.btn-icon {
  position: relative;
  left: -18px;
  display: inline-block;
  padding: 5px;
}

.btn-ytb {
  color: #fff;
  background-color: map-get($colors, 'ytb');
  border-color: map-get($colors, 'ytb');

  &:hover {
    color: #fff;
    background-color: map-get($colors, 'ytb_hover');
    border-color: map-get($colors, 'ytb_hover');
  }

  &:focus,
  .focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
}

.btn-ytb,
.btn-secondary {
  .disabled,
  &:disabled {
    color: #fff;
    background-color: map-get($colors, 'ytb_disabled');
    border-color: map-get($colors, 'ytb_disabled');
  }
}

.btn-facebook {
  color: #fff;
  background-color: map-get($colors, 'facebook');
  border-color: map-get($colors, 'facebook');

  &:hover {
    color: #fff;
    background-color: map-get($colors, 'facebook_hover');
    border-color: map-get($colors, 'facebook_hover');
  }

  &:focus,
  .focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }

  .disabled,
  &:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
}

.btn-google {
  color: #fff;
  background-color: map-get($colors, 'google');
  border-color: map-get($colors, 'google');

  &:hover {
    color: #fff;
    background-color: map-get($colors, 'google_hover');
    border-color: map-get($colors, 'google_hover');
  }

  &:focus,
  .focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }

  .disabled,
  &:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
}

.btn-primary {
  background-color: map-get($colors, 'btn-primary');
  border-color: map-get($colors, 'btn-primary');

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
}

.btn-normal {
  width: 128px;
}
