/****************** box ******************/
.card-project {
  width: 530px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  margin: 2rem auto;

  .card-header {
    background-color: map-get($colors, 'box_header_bg');
    color: map-get($colors, 'box_header_color');
    font-size: 1.3rem;
    text-align: center;
    padding: 1.5rem 0;
  }

  .form-control-lg {
    font-size: 1.1rem;
  }

  .date-input {
    width: 180px;
  }

  label {
    color: #505151;
    text-transform: uppercase;
    margin: 0;
    font-size: 11px;
    cursor: pointer;
    letter-spacing: 0.05rem;
  }
}

.edit-project {
  .form-control-lg {
    font-size: 1.1rem;
  }

  .date-input {
    width: 180px;
  }

  label {
    color: #505151;
    text-transform: uppercase;
    margin: 0;
    font-size: 11px;
    cursor: pointer;
    letter-spacing: 0.05rem;
  }
}

.project-list {
  width: 800px;
  margin-top: 3rem;

  h1 {
    font-size: 1.5rem !important;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 30px;
  }

  hr {
    margin-top: 0;
  }

  .btn-link {
    font-size: 1rem;
    color: map-get($colors, 'btn-primary');
  }

  .btn-link i {
    margin-right: 0.5rem;
  }

  .user-icon {
    color: map-get($colors, 'ytb');
    font-size: 1.5rem;
  }

  .user-list {
    font-weight: lighter;
  }

  table {
    font-size: 1rem;
  }

  .dropdown-toggle {
    &::after,
    &::before {
      display: none;
    }
  }
}

.project-list-card {
  padding: 1rem;
  margin: 0rem 0 1rem;
  border-left: 1px solid #c8c6c6 !important;
  border-bottom: 1px solid #c8c6c6 !important;
  border-right: 1px solid #c8c6c6 !important;
  border-top: 3px solid map-get($colors, 'ytb');
  font-size: 1.05rem;

  .row {
    margin: 0.25rem 0;
  }

  .btn-remove {
    font-size: 0.9rem;
    position: absolute;
    right: 0;
    color: #9f9f9f;
    font-weight: lighter;

    &:hover {
      color: red;
    }
  }

  .btn-remove i {
    margin-right: 0.5rem;
  }

  .btn-manage-users {
    font-weight: lighter;
    background: transparent;
    color: #9f9f9f;

    &:hover {
      color: map-get($colors, 'ytb');
    }
  }
}

.shared-project {
  border-top: 3px solid map-get($colors, 'shared') !important;

  .project-indication {
    color: map-get($colors, 'shared');

    i {
      font-size: 1.5rem;
      margin-right: 3px;
    }

    font-weight: lighter;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .card-project {
    width: 95%;
    margin: 2rem 0.5rem 0.5rem 0.5rem;
  }

  .project-list {
    padding: 0 1rem;

    h1 {
      font-size: 1.1rem !important;
    }

    .btn-link {
      font-size: 0.9rem;
      padding: 0 0.2rem;
    }
  }
}
