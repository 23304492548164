.grid-cols {
  display: flex;
  flex-direction: column;
  height: 98%;
  align-items: stretch;
  align-content: stretch;
}

.grid.row-1 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  min-height: 60%;
  flex-grow: 1;
  transition: flex 400ms ease-out;

  .left-panel:not(.show),
  .right-panel:not(.show) {
    flex: 0;
  }

  .left-panel,
  .right-panel {
    max-width: 22%;
    position: relative;
    flex-shrink: 1;
    flex: 1;
    transition: all 400ms ease-out;
  }

  .middle-panel {
    width: 60%;
    position: relative;
    flex: 1;
    flex-grow: 1;
    transition: all 400ms ease-out;
  }
}

.grid.row-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  flex-shrink: 1;
  max-height: 28%;
  transition: all 400ms ease-out;

  .bottom-panel {
    width: 100%;
    flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    transition: all 400ms ease-out;
  }

  .bottom-panel:not(.show) {
    transition: all 400ms ease-out;
    flex: 0;
  }
}

.left-panel-toolbar,
.right-panel-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-center;
  cursor: pointer;
  background: white;
  overflow-x: hidden;
}

.left-panel-toolbar {
  padding-right: 0.5rem;
}

.right-panel-toolbar {
  padding-left: 0.5rem;
}

.left-panel-toolbar,
.right-panel-toolbar,
.bottom-panel-toolbar {
  cursor: pointer;
  white-space: nowrap;
}
.left-panel-toolbar:hover,
.right-panel-toolbar:hover,
.bottom-panel-toolbar:hover {
  background: #e6e6e6;
  overflow-x: hidden;
}

.expand-left-panel {
  margin-top: 5rem;
  transform: rotate(90deg);
  margin-left: -3.5rem;
  margin-right: -4rem;
  width: 10rem;
}

.expand-right-panel {
  margin-top: 5rem;
  transform: rotate(90deg);
  margin-left: -4rem;
  margin-right: -3.5rem;
  width: 10rem;
}

.expand-left-panel > i,
.expand-right-panel > i,
.expand-bottom-panel > i {
  margin-left: 2rem;
  margin-right: 2rem;
}

.bottom-panel-toolbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.expand-bottom-panel {
  height: 3.5rem;
  margin-top: 0.5rem;
  margin-bottom: -1.5rem;
}
