/****************** translation ******************/
.translation-container {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 84px);

  .overlayer {
    background: map-get($colors, 'box_header_bg');
  }

  .overlay {
    background: #ffffff10;
  }
}

.translation-toolbar {
  @extend .toolbar;
  order: 0;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
  height: 53px;

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-height: 100%;
  }

  .custom-switch {
    cursor: pointer;

    .custom-control-label {
      cursor: pointer;
    }

    .custom-control-label::before {
      transform: scale(1.3);
    }
  }

  li {
    display: inline-flex;
    float: left;
    min-width: 120px;
  }

  li:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }

  .caption {
    text-transform: uppercase;
    padding-right: 0.25rem;
    font-weight: bold;
    color: #182736;
  }

  a {
    color: #074977;
    text-decoration: none;
  }

  a:hover {
    color: #0566aa;
    text-decoration: underline;
  }

  button {
    padding: 0;
    display: inline;
  }

  .btn-link {
    border-radius: 0;
    padding: 0 0.5rem;
    color: #074977;
    width: 100%;
    text-decoration: none;

    &:hover {
      background: #e6e6e6;
      text-decoration: none;

      .link {
        text-decoration: underline;
      }
    }

    i {
      font-size: 2rem;
      background-color: map-get($colors, 'ytb');
      color: transparent;
      text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
    }

    p {
      margin-top: -5px;
      margin-bottom: 0;
      font-size: 0.8rem;
      text-transform: uppercase;
    }
  }

  .btn-link:disabled,
  .btn-link.disabled {
    i {
      background-color: #848484;
    }
  }

  .division,
  .dropdown {
    display: inline-flex;
    align-items: center;
    width: 100%;

    .btn {
      height: 100%;
    }
  }

  .dropdown button,
  button.division,
  a.division {
    padding: 0 1rem;
  }

  a.division {
    span {
      white-space: nowrap;
    }
  }

  .dropdown-menu {
    max-height: 400px;
    flex-direction: column;
    padding: 0;
    box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);

    .dropdown-header {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    ul {
      overflow-y: scroll;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .dropdown-item {
      border-top: 1px solid #f2f2f2;
      line-height: 2;
    }
  }

  .dropdown-menu.show {
    display: flex;
  }

  .dropdown-bar {
    background: map-get($colors, 'ytb');
    height: 5px;
  }
}

.shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.translation-body {
  order: 1;
  align-items: stretch;
  height: 100%;
  background: #f1f3f6;

  .accordion {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;

    .card {
      min-height: 43px;
    }

    .card-header {
      @extend .toolbar;
      // background: #fff;

      color: #4d4d4d;
      text-align: left;
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;
      letter-spacing: 0.01rem;
      border-top: none;
      border-bottom: 1px solid #d9d9d9;
      position: relative;

      i {
        @extend .short-animate;
        position: absolute;
        right: 20px;
        top: 15px;
        color: #5e5e5e;
      }
    }

    .card-header:hover,
    .card-header:active {
      text-decoration: none;
    }

    .card-body {
      overflow-y: scroll;
      height: 100%;
      flex: 1;
      padding: 0.5rem;
      margin-top: 1px;
    }

    .show {
      height: calc(100% - 44px);
    }

    .active-card {
      height: 100%;

      .card-header {
        cursor: default;
      }

      i {
        transform: rotate(90deg);
        // color: map-get($colors, 'ytb' );
      }
    }
  }

  .manuscript-list {
    font-size: 1.2rem;
    background: map-get($colors, 'manuscript-bg');
    padding: 0.5rem;

    p {
      position: relative;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p:hover,
    p:active {
      background: map-get($colors, 'manuscript-highlight');
    }

    sup {
      font-weight: bold;
      top: -5px;
      padding: 0 0.25rem;
    }
  }

  .rtl {
    p {
      text-align: right;
      direction: rtl;
    }
  }
}

.panel {
  @extend .shadow;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.bar-top {
  border-top: 11px solid map-get($colors, 'ytb');
}

.bar-left {
  border-left: 11px solid map-get($colors, 'ytb');
}

.resource {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0px;
}

.resource-header {
  background: inherit;
  background-color: #ffffff;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.resource-prompt {
  font-size: 1.35rem;
}

.add-resource {
  margin: 1rem;
  position: fixed;
}

.add-resource-empty {
  font-size: large;
}

.close-resource {
  margin: 0.5rem;
  margin-left: 0.75rem;
}

.resource-content-container {
  height: 100%;
}

.resource-content-container.text {
  overflow-y: scroll;
  height: 68%;
}

.resource-content-container.text::-webkit-scrollbar {
  display: none;
}

.modal.fade .modal-dialog {
  top: 110px;
}

.book-modal {
  .modal-content {
    border: none;
  }

  .modal-header {
    border-bottom: none;
    background: #182736;
    color: #fff;
  }

  .modal-title {
    font-size: 1.2rem;
  }

  .close {
    color: #fff;
    text-shadow: none;
    opacity: 0.8;
    font-weight: normal;
    // margin: -0.75rem -1rem -1rem auto;
  }

  .modal-body {
    height: 618px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #eee;
  }

  h1 {
    font-size: 1.2rem;
    letter-spacing: 0.01rem;
  }

  h2 {
    font-size: 1.5rem;
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 0.1rem;
  }

  hr {
    border-top: 1px solid #a6a6a6;
    margin: 0.75rem 0;
  }

  .list-group-item {
    border: none;
    padding: 0.05rem;
    font-size: 1.2rem;
    margin-bottom: 3px;
    color: #0a224a;
  }

  .list-group-item:hover {
    background: #f2f2f2;
  }

  .list-group-item .btn-link {
    color: #021b33;
    font-size: 1.1rem;
    text-align: left;
  }

  .list-group-item i {
    margin-right: 0.5rem;
  }

  .book-list-body {
    flex: 1;
    background: #fff;
    padding: 0.25rem;
    margin: 0rem 0rem 2rem 0rem;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.19);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.19);
  }

  .btn-clear {
    position: absolute;
    bottom: 4px;
    right: 0px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    color: #515151;
    text-decoration: none;
  }
}

.color-law {
  color: #17b1bd;
}

.color-history {
  color: #e9730f;
}

.color-poetry {
  color: #a7ba11;
}

.color-prophets {
  color: #f084b5;
}

.color-gospels {
  color: #855e9d;
}

.color-letters {
  color: #4da648;
}

.color-prophecy {
  color: #44abb4;
}

.references {
  .reference-prompt {
    font-size: 1.35rem;
    margin-top: 3rem;
  }

  .add-reference-empty {
    font-size: large;
  }

  .dropdown-menu {
    max-height: 20rem;
    height: 20rem !important;
    width: 95%;
    flex-direction: column;
    box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    overflow-y: scroll;
    overflow-x: hidden;

    .dropdown-header {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  .dropdown-item {
    white-space: initial;
  }

  i.fa {
    transform: none !important;
    position: static !important;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    line-height: 0;
  }

  .reference-name {
    max-width: 88%;
  }
}

.translation-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .card {
    height: 100%;

    .card-header {
      @extend .toolbar;
      color: #4d4d4d;
      text-align: left;
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;
      letter-spacing: 0.01rem;
      border-top: none;
      border-bottom: 1px solid #d9d9d9;
      position: relative;
    }
  }

  .translation-list {
    overflow-y: scroll;
    padding: 0.5rem;
    height: 100%;
    position: relative;

    h3 {
      font-size: 1.2rem;
      font-weight: normal;
    }

    sup {
      font-weight: bold;
      margin-right: 0.25rem;
    }

    li {
      padding: 0;

      .verse-box {
        padding: 1.5rem 1rem;
        border-left: 4px solid transparent;

        .complete-sign {
          float: right;
          color: map-get($colors, 'ytb');
        }
      }

      &:nth-child(odd) {
        background-color: #f7f7f7;
      }
    }

    li:hover {
      .verse-box {
        border-left: 4px solid map-get($colors, 'ytb');
      }
    }

    .verse-box {
      width: 100%;
      text-align: left;
      position: relative;

      textarea {
        height: 120px;
        box-shadow: none;
      }

      textarea.form-control-changed,
      textarea.form-control-changed:focus {
        border: 1px solid map-get($colors, 'ytb');
      }

      .overlay {
        background: #dbdbdb8a;
      }

      .text-error {
        margin-left: -1rem;
      }

      .custom-switch {
        cursor: pointer;
        padding-left: 1.5rem;

        .custom-control-label {
          cursor: pointer;
        }

        .custom-control-label::before {
          transform: scale(1.2);
        }
      }

      .custom-control-label::before {
        background-color: rgb(204, 204, 204);
        border: #adb5bd solid 1px;
      }

      .custom-switch .custom-control-label::after {
        background-color: #fff;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: map-get($colors, 'ytb');
        background-color: map-get($colors, 'ytb');
      }
    }

    .close {
      right: 15px;
      top: 5px;
    }

    .verse-number {
      font-size: medium;
    }

    .form-check-label {
      cursor: pointer;
      font-size: 1.1rem;
    }

    .btn-link,
    .btn-link:hover,
    .btn-link:focus {
      text-decoration: none;
      color: #333 !important;
    }

    .btn i {
      margin-right: 0.5rem;
    }

    .row {
      margin: 0;
    }
  }

  .manuscript-text {
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }

  .text-area {
    border: 1px solid #dbdbdb;
    display: inline-block;
    width: 100%;

    textarea {
      border-radius: 0;
      border: none;
    }

    .suggestion-area {
      padding: 0.75rem;
      border-bottom: 1px solid #dbdbdb;

      .caption {
        color: #333;
        margin-right: 0.5rem;

        &::after {
          content: ':';
        }
      }

      .text {
        font-weight: 300;
        color: map-get($colors, 'suggestion-text');
      }

      .cell {
        margin-left: 0.5rem;
        border: 1px solid #dbdbdb;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background: #eaeaea;
      }
    }
  }

  .textarea-box {
    position: relative;
  }

  div.suggestion-list > ul > li {
    display: block;
  }

  .suggestion-list {
    display: block;
    position: absolute;
    z-index: 999;
    background: #fff;

    ul {
      padding: 0;
      margin: 0;
      background: #fff;
      float: left;
    }

    li {
      display: inline;
      position: relative;
      min-width: 150px;
      list-style: none;
      padding: 0.25rem;
    }

    ul ul {
      position: absolute;
      top: 0;
      display: none;
    }

    ul ul li {
      float: left;
    }

    li:hover > ul {
      display: block;
    }

    .suggestion-main-list {
      border-radius: 0.25rem !important;
      width: 100%;

      li.list-group-item {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
      }

      li button {
        width: 100%;
        text-align: left;
        border-radius: 0;
      }

      li:hover button,
      button:focus {
        background: rgb(245, 244, 244);
      }

      .suggestion-btn {
        display: inline-block;
        text-align: left;
        width: 100%;

        &.focus {
          background: #eee;
        }
      }
    }

    .suggestion-sub-list {
      width: 300px;
      max-height: 300px;
      overflow-y: scroll;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;

      li {
        font-weight: 300;
        font-size: 0.9rem;
        width: 100%;
        min-height: 70px;
        background: transparent;
        color: #000;
        border-bottom: 1px solid #eee !important;
        padding: 0.5rem !important;

        div {
          padding-bottom: 1rem;
        }

        sub {
          color: #000;
          font-size: 0.8rem;
          bottom: 0.25rem;
        }
      }

      b {
        color: #2aa4ea;
      }
    }
  }

  .target-word-main-list {
    display: none;
  }
}

.references {
  .reference-prompt {
    font-size: 1.35rem;
    margin-top: 3rem;
  }

  .add-reference-empty {
    font-size: large;
  }

  .dropdown-menu {
    max-height: 20rem;
    height: 20rem !important;
    width: 95%;
    flex-direction: column;
    box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 3px 5px -3px rgba(0, 0, 0, 0.75);
    overflow-y: scroll;
    overflow-x: hidden;

    .dropdown-header {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  .dropdown-item {
    white-space: initial;
  }

  i.fa {
    transform: none !important;
    position: static !important;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    line-height: 0;
  }

  .reference-name {
    max-width: 88%;
  }
}

.auto-suggestion-concordance {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .group {
    border: 1px solid transparent;
    float: left;
    background: #eee;
    padding: 0;
    min-height: 60px;
  }

  .group:hover {
    border: 1px solid map-get($colors, 'ytb');
  }

  .item {
    display: list-item;
    text-align: center;
    list-style-type: none;
    padding: 0.2rem;
  }

  .item-manuscript {
    font-size: 0.9rem;
    font-weight: 400;
  }

  .item-target {
    font-size: 1rem;
    margin-top: 0.7rem;
    color: map-get($colors, 'suggestion-text');

    button {
      color: inherit;
      background: none;
      border-color: transparent;
      padding: 0;
      width: 100%;
    }

    button::after {
      color: #868686;
    }

    &.display {
      button {
        background: map-get($colors, 'suggestion-text');
        color: #fff;
      }

      button::after {
        color: #fff;
      }
    }
  }

  .item-strong-x,
  .item-number {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 300;
  }

  .item-number {
    color: rgb(10, 131, 100);
  }

  .word-list {
    ul {
      margin: 0;
      background: #fff;
      float: left;
    }

    li {
      display: inline;
      position: relative;
      list-style: none;

      button {
        background: #eee;
        color: #2aa4ea;
      }
    }

    ul ul {
      position: absolute;
      top: 0;
      display: none;
      z-index: 9;
    }

    ul ul li {
      float: left;
    }

    ul li:hover > ul {
      display: block;
    }

    ul > li {
      display: block;
    }

    .dropdown-menu {
      min-width: 150px;
      max-width: 300px;
      padding: 0;

      li {
        padding: 0.5rem;
        background-color: #fff;
        width: 100%;

        div {
          white-space: normal;
        }
      }

      li:hover {
        background-color: #eee;
      }
    }

    &.display {
      ul.word-list-main-menu {
        display: block;
      }
    }

    .sub-menu {
      width: 300px;
      max-height: 300px;
      overflow-y: scroll;
      padding: 0.5rem;

      li {
        font-weight: 300;
        font-size: 0.9rem;
        width: 100%;
        min-height: 70px;
        background-color: #fff !important;
        color: #000;
        border-bottom: 1px solid #eee !important;
        padding: 0.5rem !important;

        b {
          color: #2aa4ea;
        }
      }
    }
  }
}

.auto-suggestion-concordance.flat {
  justify-content: flex-start;

  .group {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .book-modal {
    .modal-body {
      height: 500px;
    }

    .book-list-body {
      height: auto;
      margin-bottom: 2rem;
    }
  }
}

button.collapse-left-panel > i,
button.collapse-right-panel > i,
button.collapse-bottom-panel > i {
  margin-left: 1rem;
  margin-right: 1rem;
}

button.collapse-left-panel,
button.collapse-right-panel,
button.collapse-bottom-panel {
  border: none;
  outline: none;
}

button.collapse-left-panel:hover,
button.collapse-right-panel:hover,
button.collapse-bottom-panel:hover {
  background: #e6e6e6;
}

.popover {
  max-width: 600px;
  width: auto;
}

.resource-collapse-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.collapse-bottom-panel {
  width: 100%;
  text-align: center;
}

.segment-group-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  flex-wrap: wrap;
  border: 2px solid #eee;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.segment-group-container.flat {
  background-color: #eee;
}

.segment-group-container:hover {
  border: 2px solid map-get($colors, 'ytb');
}

.segment-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
}

.syntax-group-label {
  flex-basis: 100%;
  font-size: x-small;
  clear: bottom;
  width: 100%;
  background: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}

#verse-syntax-tab-natural {
  color: black !important;
  background-color: white !important;
  border: none !important;
  font-size: small !important;
  padding: 0.5rem !important;
  margin: 0.2rem;
}

#verse-syntax-tab-natural.active {
  color: blue;
  background-color: #eee !important;
}

#verse-syntax-tab-functional.active {
  color: blue;
  background-color: #eee !important;
}

#verse-syntax-tab-functional {
  color: black !important;
  background-color: white !important;
  border: none !important;
  font-size: small !important;
  padding: 0.5rem !important;
  margin: 0.2rem;
}

.nav.nav-pills {
  display: inline-flex;
  float: right;
  margin-right: 1rem;
  margin-top: -0.6rem;
}
