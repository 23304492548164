/****************** Lightbox ******************/
.loading-overlay {
  position: fixed;
  z-index: 9999;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.78);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .content {
    position: relative;
    margin: 0 auto;
    top: 38%;
    transform: translateY(-50%);
  }
  .loader {
    margin: 0 auto;
    border-top: 5px solid #26b999;
    width: 98px;
    height: 98px;
  }
}
.lightbox {
  position: fixed;
  z-index: -1;
  font-size: 12px;
  background: rgba(0, 0, 0, 0);
}
.lightbox_active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.38);
}
.lightbox2 {
  position: fixed;
  z-index: 2;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.2);

  .report-page,
  .report-page .main {
    background: none;
  }
  .main-active {
    background: #f2f2f2 !important;
  }
  .icon {
    width: 30px;
  }
}
.lightbox2-active {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.lightbox_modal {
  position: relative;
  top: 38%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 818px;
  /* height: 408px; */
  background: #2e3341;
  padding: 3em;
  color: #182348;
  border-radius: 5px;
  opacity: 0;
}
